import { Avatar, Box, Container, ThemeProvider, Typography } from "@mui/material";
import type { ErrorResponse } from "@remix-run/node";
import { Link } from "@remix-run/react";
import { IconX } from "@tabler/icons-react";

import { Button } from "~/components/button";
import { GeneralLayout } from "~/components/general-layout";
import { Routes } from "~/constants/routes";
import { themeV2 } from "~/utils/theme";

const errorDescription: Record<number, string> = {
  403: "You need to be signed in to an account that has access. If you think this is an error, contact support.",
};

export function RouteErrorPage({ error }: { error: ErrorResponse }) {
  const title = error.data || error.statusText || "Unknown error";
  const description = errorDescription[error.status];

  // We're throwing a new Response(message) without a status, so error.status is 200 by default
  const showRefreshButton = error.status >= 500 || error.status < 400;

  return (
    <GeneralLayout>
      <ThemeProvider theme={themeV2}>
        <Container
          maxWidth="xl"
          sx={{ minHeight: "calc(100vh - 72px)", display: "flex", pt: "72px", pb: "32px" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flex: "1 1 auto",
              textAlign: "center",
            }}
          >
            <Avatar sx={{ color: "red.4", bgcolor: "red.1" }}>
              <IconX size={24} />
            </Avatar>
            <Box mt={3} />
            <Typography variant="h7" component="p">
              {title}
            </Typography>
            {description && (
              <>
                <Box mt={4} />
                <Typography variant="b5">{description}</Typography>
              </>
            )}
            <Box mt={4} />
            <Button component={Link} to={Routes.landing.home()}>
              Go to Homepage
            </Button>
            {showRefreshButton && (
              <>
                <Box mt={2} />
                <Button variant="subtle" onClick={() => window.location.reload()}>
                  Refresh
                </Button>
              </>
            )}
          </Box>
        </Container>
      </ThemeProvider>
    </GeneralLayout>
  );
}
